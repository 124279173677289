import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContacts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/contacts', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchContact(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/contacts/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateContact(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/contacts/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addContact(ctx, contactData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/contacts', contactData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteContact(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/contacts/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
